/**
# This file is part of Galette.
#
# Galette is free software: you can redistribute it and/or modify
# it under the terms of the GNU General Public License as published by
# the Free Software Foundation, either version 3 of the License, or
# (at your option) any later version.
#
# Galette is distributed in the hope that it will be useful,
# but WITHOUT ANY WARRANTY; without even the implied warranty of
# MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
# GNU General Public License for more details.
#
# You should have received a copy of the GNU General Public License
# along with Foobar.  If not, see https://www.gnu.org/licenses/
*/

/** Homepage style */

@media screen and (min-width: $galette-large) {
    #home {
        figure {
            margin: .3em;
        }

        > div {
            display: flex;

            ul {
                margin: auto;;
                min-width: 40%;
            }
        }

        div:last-child {
            flex-direction: row-reverse;
        }
    }
}
