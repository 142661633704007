/**
# This file is part of Galette.
#
# Galette is free software: you can redistribute it and/or modify
# it under the terms of the GNU General Public License as published by
# the Free Software Foundation, either version 3 of the License, or
# (at your option) any later version.
#
# Galette is distributed in the hope that it will be useful,
# but WITHOUT ANY WARRANTY; without even the implied warranty of
# MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
# GNU General Public License for more details.
#
# You should have received a copy of the GNU General Public License
# along with Foobar.  If not, see https://www.gnu.org/licenses/
*/

/** main header */
$galette-header-h: 100px !default;
body > header:first-child {
  position: relative;
  height: $galette-header-h;
  width: auto;
  padding: 0;
  margin: 0 auto;

  h1 {
    width: 100%;
    text-align: center;
    font-size: 3.5em;
    margin: 0;

    a {
      color: $light-text-color;;
      text-decoration: none;
      display: inline-block;
      width: 208px;
      height: $galette-header-h;
      background: url(img/galette_pro.png) top left no-repeat;
      text-indent: 210px;
      overflow: hidden;
    }
  }
}

/** Page contents */
body > section.wrapper {
    max-width: $galette-max-width;
    margin: 0 auto;
    background: #fff;
    padding: 2em;

    > header {
        grid-column: 1 / 3;
        grid-row: 1;
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
    }

    header {
        h1 {
            display: inline-block;
            border-bottom: 1px solid $galette-orange;
            line-height: 1.2em;
            margin: .1em 0;
        }
    }
}

section#content {
    grid-column: 1;
    grid-row: 2;

    p {
        text-align: justify;
    }

    .post-content a, .home > main a {
        text-decoration: underline;
    }

    h1 {
    }

    h2.post-title {
        margin: .4em 0
    }
}

.langselector {
    display: flex;

    a {
        display: block;
        padding: .1em .2em;

        &.active {
            border-bottom: 1px solid $galette-orange;
        }

        &:hover {
            text-decoration: none;
            color: $light-text-color;
            background: $galette-blue;
            border-bottom: 1px solid transparent;
        }

    }
}

/** pagination */
.pagination {
    text-align: center;

    > * {
      display: inline-block;
    }

    a {
      border: 1px solid #98dbcc;
      padding: .5em 1em;
      background: #f6f5f4;
      color: $background-color;

      &:hover {
        background: #fff;
        text-decoration: none;
      }

      &.prev {
        margin-right: .5em;
        text-align: left;

        &:before {
            margin-right: .2em;
            content: '«';
        }
      }

      &.next {
        margin-left: .5em;
        text-align: right;
        &:after {
            margin-left: .2em;
            content: '»';
        }
      }
    }
}

/** Navigate beetween posts */
.posts-navigation {
    margin-top: 1em;
    border-top: 1px solid $galette-orange;
    text-align: center;

    a {
        color: $galette-light-grey;
    }
}

aside#sidebar {
    grid-column: 2;
    grid-row: 2;
    background: #fff;
    border-top: 1px solid $galette-orange;

  h2 {
    margin: 0;
    text-shadow: 1px 1px 1px rgba(242, 161, 4, 0.5);

    border-bottom: 1px $background-color dotted;
    color: $background-color;
  }

  h3 {
    color: $text-color;
    margin: 1em 0 .5em;
  }

  ul {
    list-style: none;
    margin: 0em 0;
    padding: 0;
  }

  li {
    display: block;
    margin: 0;

    a {
      color: $text-color;
      display: block;
      text-decoration: none;
      padding: .2em .3em;

      &:hover, &:focus, &:active {
        color: $action-color;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .main-menu li.active a {
    border-bottom: 1px solid $galette-orange;
  }
}

/** main footer */
body > footer {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: .5em;

  a {
    color: rgba(255, 255, 255, 0.7);
    padding: .1em;

    &:hover {
        text-decoration: none;
        /*background-color: rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.7);*/
    }
  }

  clear: both;
  text-align: center;
}

@media screen and (min-width: $galette-large) {
    $galette-left-pcontent: 0 1em !default;
    body > section.wrapper {
        position: relative;
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-template-rows: min-content 1fr;
        column-gap: 20px;
        row-gap: 10px;
        border-radius: 6px;

        > header {
            flex-wrap: nowrap;
            padding: $galette-left-pcontent;
        }
    }

    body > header:first-child {
        h1 {
            margin: 0;
            padding-top: .6em;
            position: relative;
            z-index: 1;
        }
    }

    aside#sidebar {
        border-top: none;
    }

    section#content {
        padding: $galette-left-pcontent;
        z-index: 2;
    }
}
