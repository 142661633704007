/**
# This file is part of Galette.
#
# Galette is free software: you can redistribute it and/or modify
# it under the terms of the GNU General Public License as published by
# the Free Software Foundation, either version 3 of the License, or
# (at your option) any later version.
#
# Galette is distributed in the hope that it will be useful,
# but WITHOUT ANY WARRANTY; without even the implied warranty of
# MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
# GNU General Public License for more details.
#
# You should have received a copy of the GNU General Public License
# along with Foobar.  If not, see https://www.gnu.org/licenses/
*/


/* Button styling */
.menu-toggle {
    display: inline-block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;

    position: absolute;
    top: 0em;
    left: 0em;
    fill: white;
}

.menu-toggle:hover,
.menu-toggle:focus {
  fill: $galette-orange;
}

/*
 Default styles + Mobile first
 Offscreen menu style
*/
.main-menu {
  position: absolute;
  display: none;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  font-size: 1.2em;
  background: $text-color;
}

.main-menu ul {
  /* Hide shadow w/ -8px while 'closed' */
  -webkit-box-shadow: -8px 0 8px rgba(0,0,0,.5);
     -moz-box-shadow: -8px 0 8px rgba(0,0,0,.5);
          box-shadow: -8px 0 8px rgba(0,0,0,.5);
  min-height: 100%;
}

aside#sidebar .main-menu li a {
  color: #fff;
  padding: 5vw 15vw;
  border-bottom: 1px solid $galette-dark-orange;

  &:hover {
    color: $galette-orange;
  }
}

.main-menu a:hover,
.main-menu a:focus {
  background: rgba(0, 0, 0, 0.4);
}

.main-menu .menu-close {
  color: white;
  display: block;

  &:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.1);
  }

  span[aria-hidden] {
        display:block;
        text-align: center;
        padding: .2em;
        font-size: 40px;
  }
}

/*
 On small devices, allow it to toggle...
*/
/*
 :target for non-JavaScript
 [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
*/
.main-menu:target,
.main-menu[aria-expanded="true"] {
  display: block;
  left: 0;
  outline: none;
  -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  box-shadow: 3px 0 12px rgba(0,0,0,.25);
}

.main-menu:target .menu-close,
.main-menu[aria-expanded="true"] .menu-close {
  z-index: 1001;
}

.main-menu:target ul,
.main-menu[aria-expanded="true"] ul {
  position: relative;
  z-index: 1000;
}

/*
 We could use `.main-menu:target:after`, but
 it wouldn't be clickable.
*/
.main-menu:target + .backdrop,
.main-menu[aria-expanded="true"] + .backdrop{
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: #000;
    background: rgba(0, 0, 0,.75);
    cursor: default;
}

/** Hide std menu title */
.mainmnulnk {
    display: none;
}

@supports (position: fixed) {
  .main-menu,
  .main-menu:target + .backdrop,
  .main-menu[aria-expanded="true"] + .backdrop{
    position: fixed;
  }
}


@media screen and (min-width: $galette-large) {
    .menu-toggle,
    .main-menu .menu-close {
        display: none;
    }

    .main-menu {
        position: relative;
        left: auto;
        top: auto;
        height: auto;
        display: block;
        font-size: 1em;
        background: none;
    }

    aside#sidebar .main-menu li a {
        color: $text-color;
        padding: .2em .3em;
        border-bottom: none;

        &:hover {
            color: $galette-blue;
        }

    }

    .main-menu ul {
        box-shadow: none;
        padding: .5em 1em;
    }

    .mainmnulnk {
        display: block;
    }
}
