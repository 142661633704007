ul.share-buttons{
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;
}

ul.share-buttons li{
    display: inline-block;
}

ul.share-buttons .sr-only{
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

ul.share-buttons img{
    width: 16px;
}
